<template>
  <div class="login" v-loading="loading">
    <div class="login__body">
      <div class="login__body--left">
        <div class="login__body--left__welCome">欢迎使用</div>
        <div class="login__body--left__appName">点一点餐</div>
        <div class="login__body--left__line"></div>
      </div>
      <div class="login__body--right">
        <div class="login__body--right__title">用户登录</div>
        <el-form ref="form" :rules="rules" :model="formData">
          <el-form-item prop="username">
            <el-input
              v-model="formData.username"
              placeholder="用户名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="formData.password"
              show-password
              placeholder="密码"
            ></el-input>
          </el-form-item>
          <div class="forgotPassword">
            <!-- 忘记密码？ -->
            </div>
          <el-form-item prop="check">
            <div class="login__body--right__check">
              <el-checkbox v-model="formData.check">我已阅读并同意</el-checkbox>
              <a href="https://api.yidiancan.net/service-agreement.html">《用户协议》</a>
            </div>
          </el-form-item>
        </el-form>
        <div class="login__body--right__button" @click="submit">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/service/auth.js'
import md5 from 'js-md5'
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      formData: {
        username: '',
        password: '',
        check: false
      },
      loading: false,
      setInterval: null,
      rules: {
        username: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ],
        check: [
          { required: true, message: '请勾选选择框', trigger: 'blur' },
          { validator: this.checkValidator, trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      shopInfo: (store) => store.login.shopInfo
    })
  },
  methods: {
    ...mapActions({
      getUserInfo: 'login/getUserInfo'
    }),
    checkValidator (rule, value, callback) {
      if (!value) {
        callback(new Error('请勾选选择框!'))
      }
      callback()
    },
    submit () {
      // 调起条码扫描

      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true
          try {
            const res = await auth.appBusinessLogin({
            username: this.formData.username,
            password: md5(md5(this.formData.password)).toUpperCase()
          })
          console.log(res)
          if (res.success) {
            window.sessionStorage.setItem('token', res.data)
            this.getUserInfo()
            this.setInterval = setInterval(() => {
              if (this.shopInfo.id) {
                this.loading = false
                clearInterval(this.setInterval)
                this.$router.replace({
                  path: '/layout',
                  query: {}
                })

                console.log(111)
              }
            }, 500)
          }
          } catch (error) {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  min-height: 100vh;
  width: 100vw;
  background-image: url("../../assets/login.png");
  background-size: 100%;
  background-repeat: no-repeat;
  // background-color: rgb(255, 187, 18);
  display: flex;
  justify-content: center;
  align-items: center;
  &__body {
    width: 1220px;
    height: 883px;
    display: flex;
    background-color: #ffffff;
    box-shadow: 0px 25px 34px #e7aa14;
    &--left {
      position: relative;
      width: 660px;
      height: 100%;
      background-image: url("../../assets/loginLeft.png");
      background-size: 100%;
      background-repeat: no-repeat;
      &__welCome {
        color: #ffffff;
        font-weight: 400;
        font-size: 48px;
        margin-left: 117px;
        margin-top: 122px;
      }
      &__appName {
        margin-top: 19px;
        margin-left: 117px;
        color: #ffffff;
        font-size: 32px;
      }
      &__line {
        width: 128px;
        height: 4px;
        margin-left: 117px;
        background-color: #ffffff;
        margin-top: 32px;
        font-weight: 400;
        font-size: 32px;
      }
    }
    &--right {
      flex: 1;
      min-width: 0px;
      display: flex;
      align-items: center;
      flex-direction: column;
      &__title {
        color: #543e3e;
        font-weight: 400;
        font-size: 40px;
        margin-top: 127px;
        margin-bottom: 108px;
      }
      &__button {
        cursor: pointer;
        background: #ffbb12;
        border-radius: 34px;
        width: 260px;
        height: 49px;
        color: #ffffff;
        font-weight: 700;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
      }
      .forgotPassword {
        color: #666666;
        font-weight: 400;
        font-size: 14px;
        text-align: right;
        cursor: pointer;
      }
      &__check {
        display: flex;
        align-items: center;
        margin-top: 17px;
        a {
          color: #349af3 !important;
          font-weight: 400;
          font-size: 14px;
          margin-left: 10px;
          text-decoration: none;
        }
      }
      ::v-deep .el-form {
        width: 389px;
      }
      ::v-deep .el-checkbox__label {
        color: #000000 !important;
        font-weight: 400;
        font-size: 14px;
      }
      ::v-deep .el-input__inner {
        border: 1px solid #543e3e;
        border-radius: 10px;
        height: 50px;
      }
    }
  }
}
.login__body--right__button:hover {
  background-color: rgb(255, 200, 63);
}
</style>
